import { Box, Button, Heading, HStack, Spacer } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { AuthContext } from '../utils/auth/AuthProvider';
import { ServicesContext } from '../services/ServicesProvider';
import { ColorModeSwitcher } from '../ColorModeSwitcher';

export default function NavigationBar() {
  const { currentUser } = useContext(AuthContext);
  const { authService } = useContext(ServicesContext);

  return (
    <Box px={4} py={2} borderBottomWidth={1}>
      <HStack>
        <Heading fontSize="xl" as="a" href="/home">
          Ubiza Admin
        </Heading>
        <Spacer />
        {currentUser && (
          <Button onClick={() => authService.logOut()}>Salir</Button>
        )}
        <ColorModeSwitcher />
      </HStack>
    </Box>
  );
}
