import React from 'react';
import AuthService from './AuthService';
import OnboardingService from './OnboardingService';
import CenterService from './CenterService';
import TestService from './TestService';

export const ServicesContext = React.createContext();

export const ServicesProvider = ({ firebaseApp, children }) => {
  return (
    <ServicesContext.Provider
      value={{
        authService: new AuthService(firebaseApp),
        centerService: new CenterService(firebaseApp),
        onboardingService: new OnboardingService(firebaseApp),
        testService: new TestService(firebaseApp),
      }}>
      {children}
    </ServicesContext.Provider>
  );
};
