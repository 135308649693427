import React from 'react';
import { Container, Text } from '@chakra-ui/react';

export default function Page() {
  return (
    <Container>
      <Text>DASHHHHBOARD</Text>
    </Container>
  );
}
