import { getFirestore } from 'firebase/firestore';
import axios from 'axios';

export default class TestService {
  constructor(firebaseApp) {
    this.firestore = getFirestore(firebaseApp);
  }

  testAuth(accessToken) {
    return axios.post(
      `${process.env.REACT_APP_BASE_API}/auth`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
    );
  }

  testAuthExpress(accessToken) {
    return axios.post(
      `${process.env.REACT_APP_BASE_API}/createOnboarding/auth-express`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
    );
  }
}
