import React from 'react';
import { CircularProgress, VStack } from '@chakra-ui/react';

export default function Loading() {
  return (
    <VStack mt={8}>
      <CircularProgress />
    </VStack>
  );
}
