import {
  collection, doc, getDoc, getDocs, getFirestore, query,
  setDoc
} from 'firebase/firestore';

export default class CenterService {
  constructor(firebaseApp) {
    this.firestore = getFirestore(firebaseApp);
  }

  getCenters() {
    const me = this;
    return new Promise(function (resolve, reject) {
      getDocs(query(collection(me.firestore, 'colleges')))
        .then(result => {
          resolve(
            result.docs.map(college => {
              const data = college.data();
              return { ...data, id: college.code };
            })
          );
        })
        .catch(reject);
    });
  }

  getCountries() {
    const me = this;
    return new Promise(function (resolve, reject) {
      getDocs(query(collection(me.firestore, 'countries')))
        .then(result => {
          resolve(
            result.docs.map(country => {
              const data = country.data();
              return { ...data, id: country.code };
            })
          );
        })
        .catch(reject);
    });
  }

  getCenter(id) {
    const me = this;
    return new Promise(function (resolve, reject) {
      getDoc(doc(me.firestore, 'colleges', id))
        .then(result => result.data())
        .then(result => {
          resolve(result);
        })
        .catch(reject);
    });
  }

  async changeActivateStatusTo(centerId, isActive) {
    const theCenter = doc(this.firestore, "colleges", centerId);

    await setDoc(theCenter, { active: isActive }, {merge: true});
  }
}
