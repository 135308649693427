import React from 'react';
import { ChakraProvider, theme } from '@chakra-ui/react';
import { app as FirebaseApp } from './utils/firebase/setup';
import { AuthProvider } from './utils/auth/AuthProvider';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  CenterDetailPage,
  CentersListPage,
  CentersPage,
  DashboardPage,
  HomePage,
  LoginPage,
  OnboardingListPage,
  OnboardingPage,
  TestPage,
} from './pages';
import CheckUserRoute from './utils/routing/CheckUserRoute';
import PrivateRoute from './utils/routing/PrivateRoute';
import { ServicesProvider } from './services/ServicesProvider';
import './App.css';
import OnboardingDetailPage from './pages/home/onboarding/OnboardingDetailPage';
import CountriesListPage from './pages/home/center/CountriesListPage';


export default function App() {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <ServicesProvider firebaseApp={FirebaseApp}>
          <BrowserRouter>
            <Routes>
              <Route path="/test" element={<TestPage />} />
              <Route path="/" element={<CheckUserRoute />}>
                <Route path="/" element={<LoginPage />} />
              </Route>
              <Route path="/" element={<PrivateRoute />}>
                <Route path="/home" element={<HomePage />}>
                  <Route index element={<DashboardPage />} />
                  <Route path="onboarding" element={<OnboardingPage />}>
                    <Route index element={<OnboardingListPage />} />
                    <Route
                      path=":onboardingId"
                      element={<OnboardingDetailPage />}
                    />
                  </Route>
                  <Route path="centers" element={<CentersPage />}>
                    <Route index element={<CentersListPage />} />
                    <Route path=":centerId" element={<CenterDetailPage />} />
                  </Route>
                  <Route path="countries" element={<CountriesListPage />} />
                </Route>
              </Route>
            </Routes>
          </BrowserRouter>
        </ServicesProvider>
      </AuthProvider>
    </ChakraProvider>
  );
}
