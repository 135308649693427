import {
  Container,
  IconButton, Image, Table,
  TableContainer,
  Tbody, Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { FaArrowRight, FaRegCheckCircle, FaWindowClose } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { ServicesContext } from '../../../services/ServicesProvider';

export default function Page() {
  const { centerService } = useContext(ServicesContext);
  const [records, setRecords] = useState(null);

  useEffect(() => {
    async function fetch() {
      setRecords(await centerService.getCenters());
    }

    fetch();
  }, []);

  if (records === null) {
    return <Loading />;
  }

  return (
    <Container maxW={'container.xl'}>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Nombre</Th>
              <Th>Estado</Th>
              <Th>Pais</Th>
              <Th>Logo</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {records &&
              records.map((rec, pos) => (
                <Tr key={`r${pos}`}>
                  <Td>({rec.code}) {rec.name}</Td>
                  <Td>
                    <IconButton
                      icon={
                        rec.active ? <FaRegCheckCircle /> : <FaWindowClose />
                      }
                      color={rec.active ? 'green' : 'red'}
                    />{' '}
                  </Td>
                  <Td>{rec.location?.countryCode ?? "-"}</Td>
                  <Td>
                    <Image src={rec.logoUri} height="2rem" />
                  </Td>
                  <Td>
                    <Link to={`${rec.code}`}>
                      <IconButton icon={<FaArrowRight />} />
                    </Link>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Container>
  );
}
