import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import React, { useState, useContext } from 'react';
import { FaEye } from 'react-icons/fa';
import { ServicesContext } from '../services/ServicesProvider';

export default function Page() {
  const { register, handleSubmit } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const { authService } = useContext(ServicesContext);

  const handleLogin = async data => {
    const user = await authService.logIn(data.email, data.password);
    console.log(user);
  };

  return (
    <>
      <Box p={4}>
        <HStack>
          <Heading fontSize="2xl">Ubiza Admin</Heading>
          <Spacer />
        </HStack>
      </Box>
      <Container>
        <form onSubmit={handleSubmit(handleLogin)}>
          <VStack spacing={6}>
            <FormControl>
              <FormLabel>Correo electronico *</FormLabel>
              <Input
                type="email"
                {...register('email', { required: true, maxLength: 200 })}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Clave *</FormLabel>
              <InputGroup>
                <Input
                  {...register('password', { required: true, maxLength: 50 })}
                  type={showPassword ? 'text' : 'password'}
                />
                <InputRightElement>
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    icon={<FaEye />}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Button type="submit" colorScheme="blue" w="100%">
              Entrar
            </Button>
          </VStack>
        </form>
        <Text fontSize='xs'>{`${process.env.REACT_APP_ENV}`}</Text>
      </Container>
    </>
  );
}
