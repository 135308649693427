import { Box, Grid, GridItem, Text, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import NavigationBar from '../components/NavigationBar';

const menu = [
  { title: 'Registros', href: 'onboarding' },
  { title: 'Centros', href: 'centers' },
  { title: 'Paises', href: 'countries' },
];

export default function Page() {
  const [selectedMenu, setSelectedMenu] = useState('');
  const location = useLocation();

  return (
    <>
      <NavigationBar />
      <Grid templateColumns="repeat(12, 1fr)" h="90vh">
        <GridItem colSpan={2} borderRightWidth={1}>
          <VStack alignItems="start" w="100%">
            {menu.map((m, pos) => (
              <Link key={`menu${pos + 1}`} to={m.href} className="fullWidth">
                <Box
                  onClick={() => setSelectedMenu(m.href)}
                  bgColor={
                    selectedMenu === m.href ||
                    location.pathname.includes(m.href)
                      ? 'pink'
                      : ''
                  }
                  w="100%"
                  pl={6}
                  py={3}
                  cursor="pointer"
                  _hover={{
                    backgroundColor: 'pink.100',
                    color: 'black',
                  }}>
                  <Text
                    color={
                      selectedMenu === m.href ||
                      location.pathname.includes(m.href)
                        ? 'black'
                        : ''
                    }>
                    {m.title}
                  </Text>
                </Box>
              </Link>
            ))}
          </VStack>
        </GridItem>
        <GridItem colSpan={10}>
          <Outlet />
        </GridItem>
      </Grid>
    </>
  );
}
