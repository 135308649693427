import {
  Box,
  Container,
  Heading,
  HStack,
  IconButton,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  Text
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { ServicesContext } from '../../../services/ServicesProvider';


function statsForColleges(onboardings) {
  let collegesMap = {};
  onboardings.forEach(ob => {
    const key = `${ob.college.code}-${ob.college.name}`
    if (!collegesMap[key]) {
      collegesMap[key] = 0;
    }

    collegesMap[key]++;
  });
  var result = Object.keys(collegesMap).map((key) => ({code: key, stats: collegesMap[key]}));
  return result;
}

function getStats(onboardings) {
  return {
    genders: {
      male: onboardings.filter(ob => ob.gender.code === "MALE").length,
      female: onboardings.filter(ob => ob.gender.code === "FEMALE").length
    },
    colleges: statsForColleges(onboardings)
  };
  
}

export default function Page() {
  const { onboardingService } = useContext(ServicesContext);
  const [records, setRecords] = useState(null);
  const [stats, setStats] = useState(null);

  useEffect(() => {
    async function fetch() {
      const onboardings = await onboardingService.getOnboardings();
      setRecords(onboardings);
      setStats(getStats(onboardings));
    }

    fetch();
  }, []);

  if (records === null) {
    return <Loading />;
  }

  return (
    <>
    {
        stats && (
          <HStack bgColor="purple.600" justify="center" py={8}>
            <Box borderWidth={1} p={6} borderRadius="lg" my={6} bgColor="white">
              <Stat>
                <StatLabel>Total Hombres</StatLabel>
                <StatNumber>{stats.genders.male}</StatNumber>
              </Stat>
            </Box>
            <Box borderWidth={1} p={6} borderRadius="lg" my={6} bgColor="white">
              <Stat>
                <StatLabel>Total Mujeres</StatLabel>
                <StatNumber>{stats.genders.female}</StatNumber>
              </Stat>
            </Box>
            <Box borderWidth={1} p={6} borderRadius="lg" my={6} bgColor="white">
              <>
              <Text mb={2} fontWeight="semibold" fontSize={14}>Alumnos por Universidad:</Text>
              {
                stats.colleges.map((colStat, key) => (
                  <HStack>
                    <Text>{colStat.code}</Text>
                    <Text fontWeight="bold">{colStat.stats}</Text>
                  </HStack>
                ))
              }
              </>
            </Box>
          </HStack>
        )
      }
    <Container maxW={'container.xl'}>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Nombre</Th>
              <Th>Estado</Th>
              <Th>Centro Estudios</Th>
              <Th>Fecha Registro</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {records &&
              records.map((rec, pos) => (
                <Tr key={`r${pos}`}>
                  <Td>
                    {rec.name} {rec.lastName}
                  </Td>
                  <Td>{rec.status}</Td>
                  <Td>
                    {rec.college.id} - {rec.college.name}
                  </Td>
                  <Td>{rec.createdAt.toLocaleString()}</Td>
                  <Td>
                    <Link to={`${rec.id}`}>
                      <IconButton icon={<FaArrowRight />} />
                    </Link>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Container>
    </>
  );
}
