import {
  Badge,
  Button,
  Container,
  Divider,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack
} from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { useGetMatchProfile } from '../../../hooks/useGetMatchProfile';
import { useGetOnboarding } from '../../../hooks/useGetOnboarding';
import { ServicesContext } from '../../../services/ServicesProvider';
import { AuthContext } from '../../../utils/auth/AuthProvider';

export default function OnboardingDetailPage() {
  const { onboardingId } = useParams();
  const { currentUser } = useContext(AuthContext);
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const { onboardingService } = useContext(ServicesContext);
  const {loading: loadingPersonal, onboarding: detail} = useGetOnboarding(onboardingId);
  const {loading: loadingProfile, profile: matchingProfile} = useGetMatchProfile(detail?.user_id ?? null);


  if (loadingPersonal) {
    return <Loading />;
  }

  const approveOnboarding = async () => {
    setIsApproving(true);
    await onboardingService.approveOnboarding(
      detail?.user_id,
      currentUser.accessToken
    );
    document.location.reload();
  };

  const rejectOnboarding = async () => {
    setIsRejecting(true);
    await onboardingService.rejectOnboarding(
      detail?.user_id,
      currentUser.accessToken
    );
    document.location.reload();
  };

  const blockOnboarding = async () => {
    setIsBlocking(true);
    await onboardingService.blockOnboarding(
      detail?.user_id,
      currentUser.accessToken
    );
    document.location.reload();
  };

  return (
    <Container maxW="container.xl">
      <HStack>
        <Heading fontSize="xl" my={8}>
          {detail.name} {detail.lastName}
        </Heading>
        <Spacer />
        {detail.status !== 'APPROVED' && (
          <>
          <Button
            colorScheme="green"
            onClick={approveOnboarding}
            isLoading={isApproving}>
            Aprobar
          </Button>
           <Button
           colorScheme="orange"
           onClick={rejectOnboarding}
           isLoading={isRejecting}>
           Rechazar
         </Button>
         </>
        )}
       
        <Button
          colorScheme="red"
          onClick={blockOnboarding}
          isLoading={isBlocking}>
          Bloquear
        </Button>
      </HStack>
      <Tabs>
        <TabList>
          {
            !loadingPersonal && (
              <>
                <Tab>Personal</Tab>
                <Tab>Documents</Tab>
              </>
            )
          }
          {
            !loadingProfile && <Tab>Match Profile</Tab>
          }
        </TabList>
        <TabPanels>
          <TabPanel>
            <HStack w="100%" alignItems="start" px={4}>
            <SimpleGrid columns={2} columnGap={8} rowGap={1}>
              <Text>Nombre:</Text>
              <Text fontWeight="bold">{detail.name}</Text>
              <Text>Apellidos:</Text>
              <Text fontWeight="bold">{detail.lastName}</Text>
              <Text>Cumpleanos:</Text>
              <Text fontWeight="bold">{detail.birthday.day}/{detail.birthday.month}/{detail.birthday.year}</Text>
              <Text>Sexo:</Text>
              <Text fontWeight="bold">{detail.gender.name}</Text>
              <Text>Correo Electronico:</Text>
              <Text fontWeight="bold">{detail.email}</Text>
              <Text>Fecha Registro:</Text>
              <Text fontWeight="bold">{detail.createdAt}</Text>
              <Text>Estado:</Text>
              <Text fontWeight="bold">{detail.status || 'NEW'}</Text>
              <Text>Universidad:</Text>
              <Text fontWeight="bold">
                ({detail.college.code}) {detail.college.name}
              </Text>
              <Text>Ultima actualizacion:</Text>
              <Text fontWeight="bold">{detail.updatedAt || '-'}</Text>
            </SimpleGrid>
            <Spacer />
            <VStack>
              <Image src={detail.documents.selfie} w="15rem" />
            </VStack>
          </HStack>
          </TabPanel>
          <TabPanel>
            <HStack w="100%" mt={10}>
            <VStack flex={1} borderWidth={1} mt={10} py={6} spacing={2}>
              <Heading fontSize="xl" mb={4}>
                Carnet Estudiante
              </Heading>
              <Image src={detail.documents.collegeId} height="15rem" />
              <Button as="a" href={detail.documents.collegeId} target="_blank">
                Abrir
              </Button>
            </VStack>
            <VStack flex={1} borderWidth={1} mt={10} py={6} spacing={4}>
              <Heading fontSize="xl" mb={4}>
                Documento Identidad
              </Heading>
              <Image src={detail.documents.documentId} height="15rem" />
              <Button as="a" href={detail.documents.documentId} target="_blank">
                Abrir
              </Button>
            </VStack>
          </HStack>
          </TabPanel>
          {
            matchingProfile && (
              <TabPanel>
            <SimpleGrid columns={2} columnGap={8} rowGap={1}>
              <Text>Nombre:</Text>
              <Text fontWeight="bold">{matchingProfile.name} {matchingProfile.lastName}</Text>
              <Text>Estado:</Text>
              <Text fontWeight="bold">{matchingProfile.status}</Text>
              <Text>Rango Edad:</Text>
              <Text fontWeight="bold">{matchingProfile.age_range?.min} - {matchingProfile.age_range?.max}</Text>
              <Text>Genero:</Text>
              <Text fontWeight="bold">{matchingProfile.gender.code}</Text>
              <Text>Orientacion:</Text>
              <Text fontWeight="bold">{matchingProfile.orientation.code}</Text>
              <Text>Interesado en:</Text>
              <Text fontWeight="bold">{matchingProfile.interest_in}</Text>
              <Text>Conectado:</Text>
              <Text fontWeight="bold">{matchingProfile.is_online ? "Si" : "No"}</Text>
              <Text>Ultimo uso:</Text>
              <Text fontWeight="bold">{matchingProfile.last_seen_time}</Text>
              <Text>Mostrar Perfil:</Text>
              <Text fontWeight="bold">{matchingProfile.show_my_profile ? "Si": "No"}</Text>
              <Text>Pais:</Text>
              <Text fontWeight="bold">{matchingProfile.country.code} - {matchingProfile.country.name}</Text>
            </SimpleGrid>
            <br/>
            <Divider />
            <br/>
            <Text>Intereses:</Text>
            <br/>
            <HStack>
                {matchingProfile.interests && matchingProfile.interests.map(interest => (
                  <Badge key={`i${interest.code}`} variant="outline">
                    {interest.name}
                  </Badge>
                ))}
            </HStack>
            <Divider />
            <br/>
            <Text>Fotos Match:</Text>
            <br/>
            <HStack>
                {matchingProfile.profilePictures && matchingProfile.profilePictures.map((photo, k) => (
                  <Image key={`i${k}`} src={photo} w="15rem" />
                ))}
            </HStack>
          </TabPanel>
            )
          }
        </TabPanels>
      </Tabs>
    </Container>
  );
}
