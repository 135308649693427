import React from 'react';
import { Button, Container } from '@chakra-ui/react';
import { AuthContext } from '../utils/auth/AuthProvider';
import { useContext } from 'react';
import { ServicesContext } from '../services/ServicesProvider';

export default function Page() {
  const { currentUser } = useContext(AuthContext);
  const { testService } = useContext(ServicesContext);

  const testAuthFirebaseAlone = async () => {
    await testService.testAuth(currentUser.accessToken);
  };

  const testAuthFirebaseExpress = async () => {
    await testService.testAuthExpress(currentUser.accessToken);
  };

  return (
    <Container>
      <Button onClick={testAuthFirebaseAlone}>Test Auth</Button>
      <Button onClick={testAuthFirebaseExpress}>Test Auth Express</Button>
    </Container>
  );
}
