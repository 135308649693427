import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';

export default class AuthService {
  constructor(firebaseApp) {
    this.firebaseApp = firebaseApp;
    this.auth = getAuth();
  }

  logIn(email, password) {
    const me = this;
    return new Promise(function (resolve, reject) {
      signInWithEmailAndPassword(me.auth, email, password)
        .then(userCredential => {
          resolve(userCredential.user);
        })
        .catch(error => {
          reject(`${error.code}: ${error.message}`);
        });
    });
  }

  async logOut() {
    await signOut(this.auth);
  }
}
