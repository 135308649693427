export function toDateString(firebaseDate) {
  const date = new Date(firebaseDate.seconds * 1000);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day < 10 ? `0${day}` : `${day}`}-${
    month < 10 ? `0${month}` : `${month}`
  }-${year}`;
}

export function toTimeString(firebaseDate) {
  const date = new Date(firebaseDate.seconds * 1000);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return `${hours < 10 ? `0${hours}` : `${hours}`}:${
    minutes < 10 ? `0${minutes}` : `${minutes}`
  }:${seconds < 10 ? `0${seconds}` : `${seconds}`}`;
}

export function toDateTimeString(firebaseDate) {
  return `${toDateString(firebaseDate)} | ${toTimeString(firebaseDate)}`;
}
