import { useContext, useEffect, useState } from "react";
import { ServicesContext } from "../services/ServicesProvider";

export const useGetOnboarding = (onboardingId) => {
    const [loading, setLoading] = useState(true);
    const [onboarding, setOnboarding] = useState(null);
    const { onboardingService } = useContext(ServicesContext);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            setOnboarding(await onboardingService.getOnboarding(onboardingId));
            setLoading(false);
        }
    
        fetchData();
    }, []);

    return {
        loading,
        onboarding
    };
}