import { useContext, useEffect, useState } from "react";
import { ServicesContext } from "../services/ServicesProvider";

export const useGetMatchProfile = (userId) => {
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState(null);
    const { onboardingService } = useContext(ServicesContext);

    useEffect(() => {
        console.log("called")
        async function fetchData() {
            if (userId == null) return;
            try {
                setLoading(true);
                setProfile(await onboardingService.getMatchingProfile(userId));
                setLoading(false);
            } catch (e) {
                setLoading(false);
                console.log(e);
            }
        }
        fetchData();
    }, [userId]);

    return {
        loading,
        profile
    };
}