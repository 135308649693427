import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../auth/AuthProvider';

const CheckUserRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentUser } = useContext(AuthContext);
  return currentUser ? <Navigate to="/home" /> : <Outlet />;
};

export default CheckUserRoute;
