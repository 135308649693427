import {
  Button,
  Container,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Spacer,
  Text,
  VStack
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { ServicesContext } from '../../../services/ServicesProvider';

export default function Page() {
  const { centerId } = useParams();
  const [detail, setDetail] = useState(null);
  const { centerService } = useContext(ServicesContext);

  useEffect(() => {
    async function fetchData() {
      setDetail(await centerService.getCenter(centerId));
    }

    fetchData();
  }, []);

  if (detail === null) {
    return <Loading />;
  }

  const changeActivateStatusTo = async (isActive) => {
    await centerService.changeActivateStatusTo(centerId, isActive);
    window.location.reload();
  }

  const activate = () => changeActivateStatusTo(true);
  const deactivate = () => changeActivateStatusTo(false);

  return (
    <Container maxW="container.xl">
      <HStack>
        <Heading fontSize="xl" my={8}>
          Informacion
        </Heading>
        <Spacer />
        <Button colorScheme="green" onClick={activate}>Activar</Button>
        <Button colorScheme="red" onClick={deactivate}>Desactivar</Button>
      </HStack>
      <HStack w="100%" alignItems="start" px={4}>
        <SimpleGrid columns={2} columnGap={8} rowGap={1}>
          <Text>Codigo:</Text>
          <Text fontWeight="bold">{detail.code}</Text>
          <Text>Nombre:</Text>
          <Text fontWeight="bold">{detail.name}</Text>
          <Text>Codigo Pais:</Text>
          <Text fontWeight="bold">{detail.location?.countryCode ?? "-"}</Text>
          <Text>Activo:</Text>
          <Text fontWeight="bold">
            {detail.active ? 'Activo' : 'Desactivado'}
          </Text>
        </SimpleGrid>
        <Spacer />
        <VStack>
          <Image src={detail.logoUri} w="15rem" />
        </VStack>
      </HStack>
    </Container>
  );
}
