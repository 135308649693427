import axios from 'axios';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  where
} from 'firebase/firestore';
import { toDateTimeString } from '../utils/date/formatter';

export default class AuthService {
  constructor(firebaseApp) {
    this.firestore = getFirestore(firebaseApp);
  }

  getOnboardings() {
    const me = this;
    return new Promise(function (resolve, reject) {
      getDocs(query(collection(me.firestore, 'onboarding')))
        .then(result => {
          resolve(
            result.docs.map(doc => {
              const data = doc.data();
              data.registeredAt = toDateTimeString(data.createdAt);
              return { ...data, id: doc.id };
            })
          );
        })
        .catch(reject);
    });
  }

  getOnboarding(id) {
    const me = this;
    return new Promise(function (resolve, reject) {
      getDoc(doc(me.firestore, 'onboarding', id))
        .then(result => result.data())
        .then(result => {
          result.registeredAt = toDateTimeString(result.createdAt);
          resolve(result);
        })
        .catch(reject);
    });
  }

  async getMatchingProfile(userId) {
    try {
      const result = await getDocs(query(collection(this.firestore, "matching_profiles"), where("user_id", "==", userId)));
      return result.docs.map(doc => doc.data())[0];
    } catch(e) {
      console.error(e);
      return null;
    }
  }

  approveOnboarding(id, accessToken) {
    return axios.post(
      `${process.env.REACT_APP_BASE_API}/updateOnboardingStatus`,
      {
        "user_id": id,
        "status": "APPROVED"
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
    );
  }

  rejectOnboarding(id, accessToken) {
    return axios.post(
      `${process.env.REACT_APP_BASE_API}/updateOnboardingStatus`,
      {
        "user_id": id,
        "status": "REJECTED"
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
    );
  }

  blockOnboarding(id, accessToken) {
    return axios.post(
      `${process.env.REACT_APP_BASE_API}/updateOnboardingStatus`,
      {
        "user_id": id,
        "status": "BLOCKED"
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
    );
  }  
}
